@media only print, only screen and (min-width: 30.063em) {
  /* for small screens */ }

@media only print, only screen and (min-width: 40.063em) {
  /* for medium screens */ }

@media only print, only screen and (min-width: 60.063em) {
  /* for large screens */ }

.main .content {
  float: left;
  padding: 8px 16px;
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.5);
  margin-bottom: 16px; }
  .main .content .ecommerce {
    display: block; }
    .main .content .ecommerce .exped,
    .main .content .ecommerce .paiement {
      padding-top: 1em;
      display: table;
      width: auto;
      margin: 0 auto;
      text-align: center; }
    .main .content .ecommerce .paiement > span {
      display: inline-block;
      text-indent: -9999px;
      width: 53px;
      height: 43px;
      background-position: center center;
      background-repeat: no-repeat; }
      .main .content .ecommerce .paiement > span.logo-creditagricole {
        background-image: url("../../../images/logo-creditagricole.png"); }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 1.5 / 1), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
          .main .content .ecommerce .paiement > span.logo-creditagricole {
            background-image: url("../../../images/logo-creditagricole@2x.png");
            -webkit-background-size: 53px 40px;
            -moz-background-size: 53px 40px;
            -o-background-size: 53px 40px;
            background-size: 53px 40px; } }
      .main .content .ecommerce .paiement > span.logo-visa {
        background-image: url("../../../images/logo-visa.png"); }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 1.5 / 1), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
          .main .content .ecommerce .paiement > span.logo-visa {
            background-image: url("../../../images/logo-visa@2x.png");
            -webkit-background-size: 43px 37px;
            -moz-background-size: 43px 37px;
            -o-background-size: 43px 37px;
            background-size: 43px 37px; } }
  .main .content .exped-picto {
    display: inline-block;
    max-width: 64px; }
    .main .content .exped-picto:before {
      content: '';
      display: block;
      height: 56px;
      width: 56px;
      margin: 0 auto;
      background-image: url("../images/picto-exped.png");
      background-repeat: no-repeat; }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 1.5 / 1), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
        .main .content .exped-picto:before {
          background-image: url("../images/picto-exped@2x.png");
          -webkit-background-size: 168px 112px;
          -moz-background-size: 168px 112px;
          -o-background-size: 168px 112px;
          background-size: 168px 112px; } }
    .main .content .exped-picto.pictoNat:before {
      background-position: 0 0; }
    .main .content .exped-picto.pictoNat.disabled:before {
      background-position: 0 -56px; }
    .main .content .exped-picto.pictoLoc:before {
      background-position: -56px 0; }
    .main .content .exped-picto.pictoLoc.disabled:before {
      background-position: -56px -56px; }
    .main .content .exped-picto.pictoEnlevement:before {
      background-position: -112px 0; }
    .main .content .exped-picto.pictoEnlevement.disabled:before {
      background-position: -112px -56px; }
  .main .content .exped-label {
    display: block;
    font-size: 0.75em;
    line-height: 1.1em;
    text-align: center; }

.main .titlePage {
  float: none;
  padding: 0;
  background: none; }

.richContent {
  min-height: 0; }
  .richContent .message.franco {
    border-color: #FFF !important;
    background-color: #da0e73 !important;
    color: #FFF !important; }
    .richContent .message.franco span {
      white-space: nowrap; }

.titlePage h1,
.richContent h1,
.richContent h2,
.richContent h3,
.richContent h6,
.richContent p {
  margin: 8px 0;
  line-height: 1.2em; }

.titlePage h1, .richContent h1 {
  font-size: 3em;
  line-height: 0.9em;
  text-align: center;
  font-family: "mathlete", "Open Sans", "sans-serif";
  font-weight: bold;
  text-shadow: 2px 0 0 #FFF, 2px 2px 0 #FFF, 0 2px 0 #FFF, -2px 2px 0 #FFF, -2px 0 0 #FFF, -2px -2px 0 #FFF, 0px -2px 0 #FFF, 2px -2px 0 #FFF; }
  .titlePage h1 span, .richContent h1 span {
    white-space: nowrap; }

.boxProduits > h2,
.richContent h2 {
  border: none;
  text-align: center;
  /*font-family: 'learning curve pro';*/
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 0.8em; }

.boxArticles {
  margin-bottom: 16px; }

.boxProduits > h2 {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.5);
  padding: 8px 16px;
  margin: 0 -30px;
  font-family: "Open Sans","sans-serif"; }

.btPhotos {
  display: block;
  background-image: url("../../../images/bt_photos.png");
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 287px;
  max-width: 340px;
  min-width: 285px;
  margin: 0 auto;
  position: relative; }

.btPhotos-img {
  display: block;
  height: 182px;
  width: 236px;
  left: 62px;
  position: absolute;
  top: 20px;
  -webkit-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  transform: rotate(5deg);
  background-color: #f00; }

.btPhotos-lib {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 48px;
  background-color: #222222;
  color: #FFF;
  text-align: center;
  /*font-family: 'learning curve pro';*/
  font-family: "Open Sans","sans-serif";
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  height: 32px;
  line-height: 30px; }

.btFidelite,
.btNewsletter {
  display: block;
  margin: 24px 0; }

.btFidelite > span,
.btNewsletter > span {
  display: block;
  padding: 16px 32px;
  color: #FFF;
  font-family: 'jardiland', 'sans-serif';
  font-size: 2.2em;
  line-height: 1em;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s; }

.btFidelite:hover > span,
.btNewsletter:hover > span {
  background-color: rgba(255, 255, 255, 0.25); }

.btFidelite {
  background-color: #f09f00; }

.btNewsletter {
  background-color: #892b61; }

.bt-nous-situer {
  position: relative;
  display: block;
  color: #FFF;
  background-color: #2d2d2d;
  margin-top: 32px; }
  .bt-nous-situer:before, .bt-nous-situer:after {
    content: '';
    position: absolute;
    width: 70px;
    height: 62px;
    background-image: url("../images/nous-situer.png");
    background-repeat: no-repeat;
    top: -7px;
    left: 7px;
    transition: transform 0.25s ease-in-out; }
  .bt-nous-situer:before {
    background-position: 0 0;
    transform: scale(1); }
  .bt-nous-situer:after {
    background-position: 0 -62px;
    transform: scale(0);
    transform-origin: 34% 40%; }
  .bt-nous-situer span {
    display: block;
    height: 48px;
    line-height: 48px;
    text-align: center;
    padding-left: 70px; }
  .bt-nous-situer:visited {
    color: #FFF; }
  .bt-nous-situer:hover, .bt-nous-situer:focus {
    color: #FFF;
    background-color: #575757; }
    .bt-nous-situer:hover:before, .bt-nous-situer:focus:before {
      transform: scale(0); }
    .bt-nous-situer:hover:after, .bt-nous-situer:focus:after {
      transform: scale(1); }

@media only screen and (min-width: 30.063em) {
  .main .content .ecommerce {
    display: table;
    width: 100%; }
    .main .content .ecommerce .exped,
    .main .content .ecommerce .paiement {
      display: table-cell;
      width: 50%;
      vertical-align: middle; } }

@media only screen and (min-width: 60.063em) {
  .main .content {
    min-height: 380px; }
    .main .content .ecommerce {
      display: block; }
      .main .content .ecommerce .exped,
      .main .content .ecommerce .paiement {
        display: table;
        width: auto;
        margin: 0 auto; }
  .btFidelite,
  .btNewsletter {
    margin-left: 16px; } }
